import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/develop-mobile-apps-phonegap",
  "date": "2014-04-27",
  "title": "DEVELOP MOBILE APPS WITH PHONEGAP",
  "author": "admin",
  "tags": ["development", "mobile", "javascript", "phonegap"],
  "featuredImage": "feature.jpg",
  "excerpt": "PhoneGap is a web-based mobile development framework based on opened source Cordova project, which allows you to create app store ready mobile applications. PhoneGap allows you to use web technologies such as HTML, CSS and JavaScript for cross platform development. It was created by Nitobi in 2009. In 2011, Nitobi was bought by Adobe, and PhoneGap was donated to Apache Foundation, and PhoneGap was renamed to Cordova."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`PhoneGap supports a wide variety of platforms:`}</p>
    <ul>
      <li parentName="ul">{`iOS`}</li>
      <li parentName="ul">{`Android`}</li>
      <li parentName="ul">{`Windows 8`}</li>
      <li parentName="ul">{`Windows Phone 7 and 8`}</li>
      <li parentName="ul">{`WebOS`}</li>
      <li parentName="ul">{`Tizen`}</li>
    </ul>
    <p>{`Building PhoneGap apps require you to know about HMTL, CSS and JavaScript.`}</p>
    <p><img parentName="p" {...{
        "src": "http://1.bp.blogspot.com/-DEJOj4wKMOE/UNhxSB-QlQI/AAAAAAAAAEg/4J01k0tvaI0/s1600/htmlcssjava.png",
        "alt": "Building PhoneGap"
      }}></img></p>
    <h2>{`How does PhoneGap works?`}</h2>
    <p>{`PhoneGap takes your web application and renders it within a web view. `}<strong parentName="p">{`Web view`}</strong>{` is a native application component, which makes web content to display within a native application.`}</p>
    <p>{`PhoneGap provides a set of JavaScript functions that enables the app to interact with the phone's native API's, such as camera, and accelerometer. This is then compiled into a native application for each platform. So PhoneGap bridges the gap between the Phone and the web application.`}</p>
    <p>{`Applications built with PhoneGap use the mobile platform’s Web view to render content. As such, the content will appear nearly identical on each platform, much as any Web page would. While you can style the controls differently on each platform, take care in doing this.`}</p>
    <p><strong parentName="p">{`What is PhoneGap Build?`}</strong></p>
    <p>{`Application container that allows you to build natively installed apps using HTML, CSS `}{`&`}{` JavaScript. There's no need to install any SDK's in your machine. PhoneGap servers will build the apps for you by compiling and packaging the apps. There are free and paid memberships available for developers. The latest version of PhoneGap might not be available and the services can go down periodically.`}</p>
    <h2>{`Plugins: Closing The Gap On Native Features`}</h2>
    <p>{`PhoneGap essentially wraps a Web view of your HTML, CSS and JavaScript in a native application. This is required because the Web view in an application does not inherently support many device features, such as access to the file system or the camera. PhoneGap has a `}<inlineCode parentName="p">{`bridging mechanism`}</inlineCode>{` that allows JavaScript running in the Web view to invoke native code contained in the application. PhoneGap comes complete with plugins to support device capabilities such as the following:`}</p>
    <ul>
      <li parentName="ul">{`accelerometer,`}</li>
      <li parentName="ul">{`camera,`}</li>
      <li parentName="ul">{`contacts,`}</li>
      <li parentName="ul">{`file system,`}</li>
      <li parentName="ul">{`media playback and recording,`}</li>
      <li parentName="ul">{`network availability.`}</li>
    </ul>
    <p>{`A full list of capabilities for each platform is available `}<a parentName="p" {...{
        "href": "http://phonegap.com/about/feature/"
      }}>{`on PhoneGap’s website`}</a>{`.
If these capabilities aren’t enough, PhoneGap may be extended with plugins that enable the developer to access more device features, including these:`}</p>
    <ul>
      <li parentName="ul">{`barcode scanning,`}</li>
      <li parentName="ul">{`Bluetooth,`}</li>
      <li parentName="ul">{`push notifications,`}</li>
      <li parentName="ul">{`text to speech,`}</li>
      <li parentName="ul">{`calendars,`}</li>
      <li parentName="ul">{`Facebook Connect.`}</li>
    </ul>
    <p>{`In previous versions of PhoneGap, a GitHub repository contained a set of `}<a parentName="p" {...{
        "href": "https://github.com/phonegap/phonegap-plugins"
      }}>{`prebuilt plugins`}</a>{`.
With the arrival of PhoneGap 3, a new plugin architecture has resulted in the old repository being deprecated. A `}<a parentName="p" {...{
        "href": "http://plugins.cordova.io/#/_browse/all"
      }}>{`registry`}</a>{` has been created for all plugins compatible with PhoneGap 3.`}</p>
    <p>{`Some command-line tools are also provided to make it easy to add the plugins to the repository for your project. We’ll see these later on in this article. PhoneGap also publishes documentation and examples on how to `}<a parentName="p" {...{
        "href": "http://docs.phonegap.com/en/3.0.0/guide_hybrid_plugins_index.md.html#Plugin%20Development%20Guide"
      }}>{`write your own`}</a>{` plugins.
Of course, the development of plugins assumes familiarity with the native platform on which the plugin is to be supported.`}</p>
    <h2>{`An HTML, CSS And JavaScript Foundation For Mobile Development`}</h2>
    <p>{`The majority of PhoneGap’s capabilities lie in non-visual components — things that access the file system, network availability, geolocation, etc.
PhoneGap does not provide much assistance with building the user interface itself. For this, you must rely on the HTML and CSS foundation that you’ve built yourself or on a framework. Applications written for mobile browsers must respect the limitations of the given mobile platform (processing speed, screen size, network speed, touch events, etc.).`}</p>
    <p>{`Unless you have been working with HTML and CSS for a long time and are well aware of these issues, developing an effective mobile application without some sort of framework can be daunting.`}</p>
    <p>{`Fortunately, `}<strong parentName="p">{`some mobile frameworks have arisen to help`}</strong>{` with this. Here are just some of the offerings in this area:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://jquerymobile.com/"
        }}>{`jQuery Mobile`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://ionicframework.com/"
        }}>{`Ionic`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.sencha.com/products/touch/"
        }}>{`Sencha Touch`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.kendoui.com/"
        }}>{`Kendo UI Complete`}</a></li>
      <li parentName="ul">{`AppGyver’s `}<a parentName="li" {...{
          "href": "http://www.appgyver.com/steroids"
        }}>{`Steroids`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://enyojs.com/"
        }}>{`Enyo`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://topcoat.io/"
        }}>{`TopCoat`}</a></li>
    </ul>
    <p>{`These frameworks vary from CSS-oriented libraries (like Topcoat) to complete MVC-based libraries with sets of mobile UI controls (like Sencha Touch). Discussing the differences between these frameworks in detail is beyond the scope of this article and would require an entire series of articles. Visit the websites above and try some of the demonstrations on several mobile devices.`}</p>
    <p>{`One distinction to be made is that some frameworks support a wider variety of devices and device versions than others. Some mobile frameworks are built on a particular MVC platform. For example, Ionic is built on the `}<a parentName="p" {...{
        "href": "http://angularjs.org/"
      }}>{`AngularJS`}</a>{` framework.
This might make a particular library more attractive to developers who are already familiar with the respective MVC framework.`}</p>
    <p>{`Frameworks such as Sencha Touch abstract the DOM from the developer through the use of APIs, freeing the developer from having to worry about details of browser vendor implementations. Sencha also provides graphic development tools, such as Sencha Architect, to aid the development process. Sencha provides commercial support and training, too, which are worth investigating.`}</p>
    <p>{`I’m often asked to recommend a framework for mobile development. But `}<inlineCode parentName="p">{`the right tool depends largely on your application’s functionality`}</inlineCode>{`, the mobile platforms you need to support, your demand for commercial support and your experience with Web and mobile development in general. I’m encouraged by some emerging frameworks, particularly Ionic, but as of the time of writing, Ionic is still in the alpha stage. If you are looking for something with a longer track record, then Sencha Touch or, for some cases, jQuery Mobile might be appropriate.`}</p>
    <p>{`To be expedient and because of the wealth of tutorials and documentation available, I’ve written our FasTip application in jQuery Mobile, which provides a wide range of support for mobile devices. It’s a good choice for applications that do not require significant customization to the user interface. The simplicity of our application makes it well suited to jQuery Mobile.`}</p>
    <h2>{`PhoneGap Or Cordova: What’s In A Name?`}</h2>
    <p>{`In the process of learning about PhoneGap, you’ll encounter the name, Cordova. The command-line tool that we’ll use in just a moment to create our PhoneGap project is named Cordova. Simply put, (`}<a parentName="p" {...{
        "href": "http://cordova.apache.org/)Cordova%5D"
      }}>{`http://cordova.apache.org/)Cordova]`}</a>{` is the open-source project that is the basis of PhoneGap. Think of it as the engine that drives PhoneGap.`}</p>
    <p>{`In most cases, the names may be used interchangeably. With `}<strong parentName="p">{`PhoneGap`}</strong>{` already being trademarked, a new name was needed when the project was open-sourced — hence, Cordova. “PhoneGap” continues to be used by Adobe for commercial products. Most people still refer to
the project as a whole as PhoneGap.`}</p>
    <h2>{`Installing PhoneGap`}</h2>
    <p>{`With the introduction of version 3, installing PhoneGap’s tools and setting up projects have been greatly simplified, thanks to the new `}<a parentName="p" {...{
        "href": "http://docs.phonegap.com/en/3.3.0/guide_cli_index.md.html#The%20Command-Line%20Interface"
      }}>{`command-line interface`}</a>{`(or CLI). The command-line tools are based on Node.js; thus, you must install it before installing PhoneGap. Once Node.js is in place, simply run one command for the node package manager to install PhoneGap’s tools:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm install -g cordova
`}</code></pre>
    <h2>{`Considerations For Supporting Multiple Platforms`}</h2>
    <p>{`We’ve seen that by using PhoneGap and a framework like jQuery Mobile, building a cross-device application is easy. One caveat with jQuery Mobile is that both iOS and Android users would see the same UX; that is, the UX would not be tailored to the respective platform, much like a Web page looks the same whether viewed on OS X or Windows.`}</p>
    <p>{`Attempting to mimic the native platform controls in HTML and CSS is generally a bad idea.
While you could approximate the `}<inlineCode parentName="p">{`look`}</inlineCode>{` of the native platform, mimicking the `}<inlineCode parentName="p">{`feel`}</inlineCode>{` of the platform is exceedingly difficult. The controls might not respond to taps and other gestures in the same way. The result is usually a UX that just feels wrong. Successful PhoneGap projects generally eschew this approach in favour of a platform-independent UX.`}</p>
    <p>{`If your project requires an experience that closely matches that of the native platform, PhoneGap might not be the right choice.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2014-04-27-develop-mobile-apps-phonegap/phonegap-cordova-500-opt.png",
        "alt": "Multiple Platforms"
      }}></img></p>
    <p>{`HTML5 capabilities vary widely across devices. While iOS and Android browsers are built on WebKit, some features are implemented only in the latest versions of the platforms. One useful website for checking browser version capabilities is `}<a parentName="p" {...{
        "href": "http://mobilehtml5.org/"
      }}>{`Mobile HTML5`}</a>{`. The browsers on some old platforms, particularly Android 2.x, have various rendering bugs, which could also hamper your development.`}</p>
    <p>{`Frameworks such as jQuery Mobile and Sencha Touch take these compatibility issues into account. If you choose to roll your solution, perhaps to gain greater control over the UX, then you will need to plan additional time for testing, particularly for old device versions, on which HTML5 support is less consistent.`}</p>
    <p>{`jQuery Mobile makes it easy to get started and provides a good feature set for simple applications. Much of the functionality on the screens of our sample application is provided by jQuery Mobile itself, leaving us with less code to write. However, the speed of development with jQuery Mobile comes at the expense of customization. While jQuery Mobile does offer a “theme roller” tool to customize appearance, much of that is “chrome” related to colours and visual attributes like rounded corners.`}</p>
    <p>{`Assess whether your application fits jQuery Mobile’s paradigm.
The platform does offer some alternate layouts for things like forms, but the options are still somewhat limited. If your application requires a truly custom look or layout, then you might find jQuery Mobile too confining. Consider one of the frameworks suggested earlier. Or adopt a “best of breed” approach, mixing a CSS-oriented framework (such as `}<a parentName="p" {...{
        "href": "http://twitter.github.io/bootstrap/"
      }}>{`Twitter Bootstrap`}</a>{`, `}<a parentName="p" {...{
        "href": "http://foundation.zurb.com/"
      }}>{`Zurb Foundation`}</a>{` or `}<a parentName="p" {...{
        "href": "http://maker.github.io/ratchet/"
      }}>{`Ratchet`}</a>{`) with an MVC framework (such as `}<a parentName="p" {...{
        "href": "http://angularjs.org/"
      }}>{`AngularJS`}</a>{` or `}<a parentName="p" {...{
        "href": "http://backbonejs.org/"
      }}>{`Backbone.js`}</a>{`).`}</p>
    <p>{`Some of the large mobile solutions, such as `}<a parentName="p" {...{
        "href": "http://www.sencha.com/products/touch/"
      }}>{`Sencha Touch`}</a>{` and `}<a parentName="p" {...{
        "href": "http://ionicframework.com/"
      }}>{`Ionic`}</a>{`, marry a powerful MVC platform to a set of prebuilt controls focused on mobile development. These tools are attractive and worthy of consideration. But bear in mind the different range of devices that each of these tools supports. At the moment, Sencha Touch supports more devices than Ionic, but jQuery Mobile supports even more. This should also factor in your selection process.`}</p>
    <h2>{`Using A Native Plugin`}</h2>
    <p>{`If you try our sample application on iOS 7, you’ll run into an issue with the device’s status bar that has affected many PhoneGap applications. That is, the status bar now overlaps the top of the application:`}</p>
    <h2>{`Building In The Cloud`}</h2>
    <p>{`Generally speaking, to build an application for a particular platform, you must install the SDK for that platform on your machine. This could be a problem if, for instance, you’re on a Mac and want to target Windows tablets, whose SDK requires you to be on a Windows machine. Adobe offers a service named `}<a parentName="p" {...{
        "href": "https://build.phonegap.com/"
      }}>{`PhoneGap Build`}</a>{`to help in this situation.`}</p>
    <p>{`PhoneGap Build enables you to upload a ZIP file containing the HTML, CSS and JavaScript of your application. Additionally, PhoneGap 3 enables you to `}<inlineCode parentName="p">{`submit an application to PhoneGap Build right from the command line`}</inlineCode>{`; from there, PhoneGap Build takes over and produces a deployment bundle for the desired platform.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2014-04-27-develop-mobile-apps-phonegap/build-500-opt.png",
        "alt": "Building In The Cloud"
      }}></img></p>
    <p>{`Once the build process is complete, you may either download the deployment bundle and manually install it to your device or take a picture of a QR code on PhoneGap Build’s website and download the deployment package directly to your mobile device. What’s more, PhoneGap Build supports a feature named `}<a parentName="p" {...{
        "href": "http://docs.build.phonegap.com/en_US/3.1.0/tools_hydration.md.html#Hydration"
      }}>{`Hydration`}</a>{`, which enables the application to download updates to the HTML, CSS and JavaScript files whenever it launches.`}</p>
    <p>{`This means that testers will always be running the latest version of your application, without having to go through the traditional updating process. Note that, at least for now, Hydration is meant for development and testing — not for final production code that will be submitted to the app store.`}</p>
    <h2>{`Development in the Cloud`}</h2>
    <p>{`You can do more than simply compile and package your application in the cloud. Cloud-based `}<a parentName="p" {...{
        "href": "http://www.icenium.com/"
      }}>{`Icenium`}</a>{` provides a browser-based IDE that lets you edit code in a browser and immediately turn those edits into a deployment package. Using an application in the iOS App Store named `}<a parentName="p" {...{
        "href": "http://www.icenium.com/product/ion"
      }}>{`Icenium Ion`}</a>{`, you can dynamically load updates to your application as you change the code in the Web-based IDE.`}</p>
    <p>{`In addition, Icenium bundles a license to Telerik’s !KendoUI](`}<a parentName="p" {...{
        "href": "http://www.kendoui.com/)library"
      }}>{`http://www.kendoui.com/)library`}</a>{`. I’ve used this to make changes to an iOS PhoneGap application while on a Windows-based laptop. Icenium also offers a Windows desktop IDE named (`}<a parentName="p" {...{
        "href": "http://www.icenium.com/product/graphite)Graphite%5D"
      }}>{`http://www.icenium.com/product/graphite)Graphite]`}</a>{`, which expands on the capabilities of the browser-based tool.`}</p>
    <p>{`Another alternative to PhoneGap Build is (`}<a parentName="p" {...{
        "href": "http://www.appgyver.com/)AppGyver%5D"
      }}>{`http://www.appgyver.com/)AppGyver]`}</a>{`. It is not an IDE like Icenium, but it does offer a UX library, named `}<a parentName="p" {...{
        "href": "http://www.appgyver.com/steroids"
      }}>{`Steroids`}</a>{`, that facilities navigation between Web views using some native controls. Note that for page content itself, you must still use HTML and CSS.`}</p>
    <p>{`The main downside to cloud-based development services is that they have limited support for PhoneGap plugins. However, new plugins are being supported all the time. Determine your application’s needs to see whether a particular cloud-based tool supports the plugins you require.`}</p>
    <h2>{`Debugging Your Application`}</h2>
    <p>{`In most cases, when attempting to debug an application, you’ll be mostly concerned with debugging the JavaScript, examining the console log and perhaps doing some interactive inspection and manipulation of the DOM. Unfortunately, there is no integrated way to debug across all platforms. However, there are several useful techniques, covered below.`}</p>
    <h2>{`Chrome DevTools`}</h2>
    <p>{`I generally find it easiest to work with Chrome DevTools by treating my pages as standard Web pages loaded in a desktop browser (although this doesn’t help with the portions of code that rely on PhoneGap plugins). Chrome DevTools provides an `}<a parentName="p" {...{
        "href": "https://developers.google.com/chrome-developer-tools/docs/mobile-emulation"
      }}>{`emulation panel`}</a>{` that simulates the aspect ratios, touch events and more of various mobile device screens.`}</p>
    <h2>{`Apache Ripple`}</h2>
    <p>{`Apache’s `}<a parentName="p" {...{
        "href": "http://ripple.incubator.apache.org/"
      }}>{`Ripple`}</a>{` is a NodeJS project that serves PhoneGap content in a desktop browser. Ripple enables you to experiment with different screen sizes and orientations. It also allows you to simulate geolocation coordinates and network connectivity events, as well as allows JavaScript code to invoke many of PhoneGap’s core plugins while running in a desktop browser.`}</p>
    <h2>{`Safari to debug web views on iOS Apps`}</h2>
    <p>{`Starting with Safari 6, Apple has included support for using the Web inspector in `}<a parentName="p" {...{
        "href": "http://www.tricedesigns.com/2013/03/18/on-device-debugging-with-phonegap-ios-6/"
      }}>{`desktop Safari to debug Web views`}</a>{` running in the simulator. With your app running in the simulator, simply go to the `}<strong parentName="p">{`Develop`}</strong>{` menu in Safari and look for the* *iPhone Simulator** option. This will connect the Web inspector to the running instance of your PhoneGap application and will allow to you manipulate the DOM, set breakpoints in JavaScript, etc.`}</p>
    <h2>{`Chrome DevTools to debug web views in Android Apps`}</h2>
    <p>{`Starting with version 4.4, Android’s Web view is now based on Google Chrome. With this change comes the ability to `}<a parentName="p" {...{
        "href": "https://developers.google.com/chrome-developer-tools/docs/remote-debugging#debugging-webviews"
      }}>{`connect to the Web view from DevTools`}</a>{` and to debug interactively, just like you can with iOS. If you don’t have a 4.4 device, fear not: It works with the 4.4 emulator as well. Note that you’ll need to add a (`}<a parentName="p" {...{
        "href": "https://github.com/jrstarke/webview-debug)special"
      }}>{`https://github.com/jrstarke/webview-debug)special`}</a>{` plugin] to your PhoneGap project to enable Web view debugging on Android.`}</p>
    <h2>{`Web Inspector Remote`}</h2>
    <p><a parentName="p" {...{
        "href": "http://people.apache.org/~pmuellr/weinre/docs/latest/Home.html"
      }}>{`Web Inspector Remote`}</a>{` (WEINRE) provides a partial set of debugging features, including DOM manipulation and access to the console. However, it does not support JavaScript debugging. Add WEINRE to your application by including a script tag in your application’s HTML and running a NodeJS server that this script connects to. The main advantage of WEINRE is that it works with earlier versions of WebKit on mobile devices and is one way of debugging cross-platform.`}</p>
    <p>{`The `}{`[PhoneGap wiki]`}{` (`}<a parentName="p" {...{
        "href": "https://github.com/phonegap/phonegap/wiki/Debugging-in-PhoneGap"
      }}>{`https://github.com/phonegap/phonegap/wiki/Debugging-in-PhoneGap`}</a>{`) also has a page on debugging, with links to other tools.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      